<template>
  <div class="event-coupon-select">
    <el-select
      v-model="currentClassTicket"
      popper-class="select-popper"
      placeholder="選擇堂票"
      clearable
      @visible-change="showClassTicketSelectModal = true"
    >
      <el-option
        :label="findCouponName(currentClassTicket)"
        :value="currentClassTicket"
      />
    </el-select>
    <p v-if="isEmpty" class="text-danger text-sm leading-normal">找不到先前綁定的項目，請重新綁定</p>
    <ClassTicketAdvanceSelect
      v-if="showClassTicketSelectModal"
      :data="classTicketList"
      :selectedData="classTicketId ? [classTicketId] : []"
      :typeOptions="couponTypeConfig"
      :disabledExp="true"
      @confirm="onAddCoupon"
      @close="showClassTicketSelectModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import ClassTicketAdvanceSelect from '@/components/Select/ClassTicketAdvanceSelect.vue'
import { couponTypeConfig } from '@/config/couponExchange'
import { getAllDataFromApi } from '@/utils/helper'
import { GetClassTicket, GetClassTicketCount } from '@/api/classTicket'
import { find } from 'lodash'
import store from '@/store'
import FormsContext from '../formsContext'

export default defineComponent({
  name: 'EventCouponSelect',
  components: { ClassTicketAdvanceSelect },
  props: ['classTicketId'],
  emits: ['update:classTicketId'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const classTicketList = ref([])
    const showClassTicketSelectModal = ref(false)
    const isEmpty = ref(false)
    const currentClassTicket = computed({
      get: () => props.classTicketId,
      set: (newValue) => emit('update:classTicketId', newValue),
    })

    const findCouponName = (id) => {
      const coupon = find(classTicketList.value, { id })
      if (coupon) return coupon.name
      return ''
    }

    const getClassTicketCount = async () => {
      const [res, err] = await GetClassTicketCount({
        shopId: shopId.value,
      })
      if (err) throw err
      return res.count || 0
    }

    const getAllClassTicket = async () => {
      let max
      try {
        max = await getClassTicketCount()
      } catch (error) {
        window.$message.error(error)
        return
      }
      const config = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
      }
      const [res, err] = await getAllDataFromApi(
        max,
        GetClassTicket,
        config,
        true,
      )
      if (err) return window.$message.error(err)
      classTicketList.value = res
    }

    const onAddCoupon = (coupon) => {
      if (coupon.length > 0) {
        currentClassTicket.value = coupon[0]
        const couponData = find(classTicketList.value, { id: coupon[0] })
        findCoupon(coupon[0])
        FormsContext.setTempData('relateData', couponData)
        emit('change', couponData)
      }
    }
    const findCoupon = async (id) => {
      if (id === null) {
        isEmpty.value = false
        return
      }

      const coupon = find(classTicketList.value, { id })
      if (coupon) isEmpty.value = false
      else isEmpty.value = true
    }
    onMounted(async () => {
      await getAllClassTicket()
      await findCoupon(currentClassTicket.value)
    })

    return {
      currentClassTicket,
      couponTypeConfig,
      classTicketList,
      showClassTicketSelectModal,
      findCouponName,
      onAddCoupon,
      isEmpty,
    }
  },
})
</script>

<style lang="postcss">
.select-popper {
  @apply hidden;
}
</style>
