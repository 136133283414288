<template>
  <div class="event-marketing-setting">
    <p class="card-title">事件設定</p>
    <el-form
      ref="formRef"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <el-form-item label="事件類型" prop="source" required>
        <el-select
          v-model="formData.source"
          :disabled="isEdit"
          @change="onSourceChange"
        >
          <el-option
            v-for="item in featureKeysByEventSourceConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="showMultiTransaction" prop="multiSources">
        <template slot="label">
          <FormItemTooltipLabel label="銷售涵蓋管道">
            您可以勾選一項以上的管道，只要其中一個管道符合條件，就能觸發事件行銷
          </FormItemTooltipLabel>
        </template>
        <el-checkbox-group v-model="formData.multiSources" class="flex flex-col">
          <el-checkbox
            v-for="item in featureKeysByEventSourceContainsConfig"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="事件範本" prop="type" required>
        <el-select
          v-model="formData.type"
          no-data-text="請先選擇事件類型"
          :disabled="isEdit"
          @change="
            (e) => {
              typeChangeHandler()
              changeType(e)
            }
          "
        >
          <el-option
            v-for="item in eventType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <p
          v-if="
            formData.source === 'birthday' &&
              formData.type === 'birthCurrentMonth'
          "
          class="tips"
        >
          於活動期間系統會在每月的 1 號凌晨 0:00 計算當月壽星資格。
        </p>
      </el-form-item>

      <p
        v-if="formData.source === 'memberExternalTransactionCompleted'"
        class="tips text-sub flex"
        style="margin-bottom: 20px; gap: 8px"
      >
        <span>提醒：請留意第三方交易規則說明：</span>
        <router-link :to="{name: 'PosPospal'}" target="_blank">
          <p class="underline">瑞乘 ( 銀豹 ) POS</p>
        </router-link>
      </p>
      <el-form-item v-if="showSingleConsumption" label="單筆消費規則" prop="singleConsumption" required>
        <el-select
          v-model="formData.singleConsumption"
          no-data-text="請選擇"
          :disabled="isEdit"
          @change="(e) => {changeSingleConsumption(e)}"
        >
          <el-option
            v-for="item in singleConsumptionTypeConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <p class="tips">{{ sendLimitWord }}</p>
      </el-form-item>
      <el-form-item
        v-if="showProductRow('memberStoreOrderCompleted')"
        label="選擇指定會員商城商品"
        prop="spec.ids"
      >
        <EventMemberStoreProduct
          :productId.sync="formData.spec.ids"
          :isDisabled="isEdit"
        />
      </el-form-item>
      <el-form-item
        v-if="showProductRowWithMulti('memberStoreOrderCompleted')"
        label="選擇指定會員商城商品"
        :prop="isEdit ? '' : 'multiSpecs[0].ids'"
      >
        <EventMemberStoreProduct
          :productId.sync="memberStoreOrderCompletedIds"
          :isDisabled="isEdit"
        />
      </el-form-item>
      <el-form-item
        v-if="showProductRow('saleRecordCompleted')"
        label="選擇指定銷售商品"
        prop="spec.ids"
      >
        <EventSalesProduct
          :productId.sync="formData.spec.ids"
          :isDisabled="isEdit"
        />
      </el-form-item>
      <el-form-item
        v-if="showProductRowWithMulti('saleRecordCompleted')"
        label="選擇指定銷售商品"
        :prop="isEdit ? '' : 'multiSpecs[1].ids'"
      >
        <EventSalesProduct
          :productId.sync="salesRecordCompletedIds"
          :isDisabled="isEdit"
        />
      </el-form-item>
      <el-form-item
        v-if="showProductRow('appointmentOrderCompleted')"
        label="選擇指定預約項目"
        prop="spec.ids"
      >
        <EventAppointmentProduct
          :productId.sync="formData.spec.ids"
          :isDisabled="isEdit"
        />
      </el-form-item>
      <el-form-item
        v-if="showProductRowWithMulti('appointmentOrderCompleted')"
        label="選擇指定預約項目"
        :prop="isEdit ? '' : 'multiSpecs[2].ids'"
      >
        <EventAppointmentProduct
          :productId.sync="appointementOrderCompletedIds"
          :isDisabled="isEdit"
        />
      </el-form-item>
      <el-form-item
        v-if="showProductRow('ecOrderCompleted')"
        label="選擇指定電商商品"
        prop="spec.ids"
      >
        <EventEcommerceProduct
          :productId.sync="formData.spec.ids"
          :isDisabled="isEdit"
        />
      </el-form-item>
      <el-form-item
        v-if="showProductRowWithMulti('ecOrderCompleted')"
        label="選擇指定電商商品"
        :prop="isEdit ? '' : 'multiSpecs[3].ids'"
      >
        <EventEcommerceProduct
          :productId.sync="ecOrderCompletedIds"
          :isDisabled="isEdit"
        />
      </el-form-item>
      <el-form-item
        v-if="showSubscriptionPlan"
        label="選擇指定訂閱方案"
        prop="subscriptionConfig.planId"
        required
      >
        <EventSubscriptionProduct
          :planId.sync="formData.subscriptionConfig.planId"
          :isDisabled="isEdit"
        />
      </el-form-item>
      <el-form-item
        v-if="showBenefitLimit"
        label="權益發放限制"
        prop="subscriptionConfig.skipBenefitIfPaymentFailed"
      >
        <el-checkbox
          v-model="formData.subscriptionConfig.skipBenefitIfPaymentFailed"
          :disabled="isEdit"
        >
          有扣款失敗紀錄時，不觸發權益發放
        </el-checkbox>
      </el-form-item>
      <el-form-item
        v-if="showProductRow('memberExternalTransactionCompleted')"
        label="指定第三方商品名稱"
        prop="spec.names"
      >
        <template slot="label">
          <FormItemTooltipLabel label="指定第三方商品名稱">
            說明：請留意您在POS方輸入的商品名稱，空白鍵、符號、英文大小寫皆須完全吻合。<br>
            可以設定多項指定商品，其中一項符合指定條件即可。例如有A、B兩個指定商品，代表會員只需購買A或B且達成條件即可觸發行銷獎勵。
          </FormItemTooltipLabel>
        </template>
        <EventExternalProduct
          :productName.sync="formData.spec.names"
          :isDisabled="isEdit"
        />
      </el-form-item>
      <el-form-item
        v-if="showProductRowWithMulti('memberExternalTransactionCompleted')"
        label="指定第三方商品名稱"
        :prop="isEdit ? '' : 'multiSpecs[4].names'"
      >
        <template slot="label">
          <FormItemTooltipLabel label="指定第三方商品名稱">
            說明：請留意您在POS方輸入的商品名稱，空白鍵、符號、英文大小寫皆須完全吻合。<br>
            可以設定多項指定商品，其中一項符合指定條件即可。例如有A、B兩個指定商品，代表會員只需購買A或B且達成條件即可觸發行銷獎勵。
          </FormItemTooltipLabel>
        </template>
        <EventExternalProduct
          :productName.sync="memberExternalTransactionCompletedNames"
          :isDisabled="isEdit"
        />
      </el-form-item>
      <MemberInviteAmountSettingBlock
        :form.sync="formData"
        :isEdit="isEdit"
        :setAccumulation="setAccumulation"
        :amountTypeChangeHandler="amountTypeChangeHandler"
      />

      <el-form-item v-if="showAccumulationConfig" label="累積計算方式" required>
        <el-select
          v-model="formData.sumUp.type"
          :disabled="isEdit"
          @change="setAccumulation"
        >
          <el-option
            v-for="item in eventSumUpConfigConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <p v-if="formData.sumUp.type === 'rate'" class="tips">
          規則說明：活動期間的所有消費累積會每次計算，如「累積」達到條件的倍數，則會贈送該倍數的獎勵
          <el-tooltip placement="right">
            <div slot="content">
              舉例每累積3000元送 1張，A第一次消費3000元<br>
              時贈送1張 ; 當A第二次消費6000元時，由於滿足<br>
              3000元的倍數，則會再贈送2張
            </div>
            <span class="underline">看範例</span>
          </el-tooltip>
        </p>
        <p v-if="formData.sumUp.type === 'once'" class="tips">
          規則說明：同一位會員於活動期間只能領取 1 次
        </p>
      </el-form-item>

      <el-form-item v-if="showAmountConfig" label="消費滿額設置" prop="amountConfig">
        <el-radio-group
          v-model="formData.amountConfig.amountType"
          class="flex flex-col"
          style="gap: 20px; padding-top: 8px"
          :disabled="isEdit"
          @change="amountTypeChangeHandler"
        >
          <div v-if="formData.singleConsumption === 'repeat'">
            <el-radio label="repeatRate">
              {{ amountConfig.configSpec.title }}
              <div
                class="flex items-center gap-[20px]"
                style="margin-left: 25px; margin-top: 8px; gap: 20px"
              >
                單筆消費滿
                <el-form-item prop="amountConfig.amountRate">
                  <el-input
                    v-model="formData.amountConfig.amountRate"
                    :disabled="
                      isEdit || formData.amountConfig.amountType !== 'repeatRate'
                    "
                    style="width: 100px"
                    placeholder="請輸入"
                  />
                </el-form-item>
                {{ amountConfig.configSpec.unit }}
              </div>
            </el-radio>
          </div>
          <template v-else>
            <div>
              <el-radio label="threshold">
                {{ amountConfig.configSpec.title }}
                <div
                  class="flex items-center gap-[20px]"
                  style="margin-left: 25px; margin-top: 8px; gap: 20px"
                >
                  單筆消費滿
                  <el-form-item prop="amountConfig.amountMin">
                    <el-input
                      v-model="formData.amountConfig.amountMin"
                      :disabled="
                        isEdit || formData.amountConfig.amountType !== 'threshold'
                      "
                      style="width: 100px"
                      placeholder="請輸入"
                    />
                  </el-form-item>
                  {{ amountConfig.configSpec.unit }}
                </div>
              </el-radio>
            </div>
            <div v-if="amountConfig.configRange && formData.singleConsumption === 'once'">
              <el-radio label="range">
                {{ amountConfig.configRange.title }}
                <div
                  class="flex items-center gap-[20px]"
                  style="margin-left: 25px; margin-top: 8px; gap: 20px"
                >
                  單筆消費滿
                  <el-form-item prop="amountConfig.amountRangeMin">
                    <el-input
                      v-model="formData.amountConfig.amountRangeMin"
                      :disabled="
                        isEdit || formData.amountConfig.amountType !== 'range'
                      "
                      style="width: 100px"
                      placeholder="請輸入"
                    />
                  </el-form-item>
                  ～
                  <el-form-item prop="amountConfig.amountMax">
                    <el-input
                      v-model="formData.amountConfig.amountMax"
                      :disabled="
                        isEdit || formData.amountConfig.amountType !== 'range'
                      "
                      style="width: 100px"
                      placeholder="請輸入"
                    />
                  </el-form-item>
                  {{ amountConfig.configRange.unit }}
                </div>
              </el-radio>
            </div>
          </template>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        v-if="showAccumulationConfig"
        :label="amountConfig.configSpec.title"
        required
      >
        <div
          style="margin-left: 25px"
          class="text-[16px] flex items-center gap-[20px]"
        >
          累積訂單滿
          <el-input
            v-model="formData.amountConfig.amountMin"
            style="width: 100px"
            placeholder="請輸入"
            :disabled="isEdit"
          />
          {{ amountConfig.configSpec.unit }}
        </div>
      </el-form-item>

      <el-form-item v-if="showAccumulationConfig" prop="sumUpBackToDays">
        <div
          class="flex items-center text-[#333]"
          style="gap: 8px; margin-bottom: 8px"
        >
          <p class="font-medium text-[#333] text-[16px]">
            <span class="text-danger">* </span>回溯歷史區間
          </p>
          <el-tooltip placement="right">
            <div slot="content">累積訂單可追加活動期間「開始日期」往前回推的天數進行計算</div>
            <span class="material-icons">help_outline</span>
          </el-tooltip>
        </div>
        <el-radio-group
          v-model="formData.sumUp.backToDays"
          class="flex flex-col"
          style="gap: 20px; padding-top: 8px"
          :disabled="isEdit"
        >
          <el-radio
            v-for="item in eventHistoryConfig"
            :key="item.value"
            :label="item.value"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  nextTick,
  computed,
  watch,
} from 'vue'
import { noEmptyRules, noEmptyArray, minArrayRules } from '@/validation'
import {
  eventSourceConfig,
  eventSumUpConfigConfig,
  eventHistoryConfig,
  eventTypeConsumption,
  textSettingConfig,
  amountSettingConfig,
  productConfig,
  accumulationConfig,
  singleConsumptionConfig,
  eventSourceContainsConfig,
  eventSoruceMappingSpecType,
  singleConsumptionTypeConfig,
  singleConsumptionWord,
} from '@/config/marketing'
import { get, set, isArray, map, find, filter, includes } from 'lodash'
import { usePermissions } from '@/use/permissions'
import EventMemberStoreProduct from './EventMemberStoreProduct.vue'
import EventSalesProduct from './EventSalesProduct.vue'
import EventAppointmentProduct from './EventAppointmentProduct.vue'
import EventEcommerceProduct from './EventEcommerceProduct.vue'
import EventSubscriptionProduct from './EventSubscriptionProduct.vue'
import EventExternalProduct from './EventExternalProduct.vue'
import MemberInviteAmountSettingBlock from './MemberInviteAmountSettingBlock.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
export default defineComponent({
  name: 'EventMarketingSetting',
  components: {
    EventMemberStoreProduct,
    EventSalesProduct,
    EventAppointmentProduct,
    EventEcommerceProduct,
    EventSubscriptionProduct,
    EventExternalProduct,
    MemberInviteAmountSettingBlock,
    FormItemTooltipLabel,
  },
  props: [
    'FormsContext',
    'eventData',
    'setSourceType',
    'setAccumulation',
    'setEventType',
    'setSingleConsumption',
  ],
  setup (props) {
    const formRef = ref(null)
    const formData = reactive({
      source: null,
      type: null,
      amountConfig: {
        amountType: 'threshold',
        amountMin: null,
        amountRangeMin: null,
        amountMax: null,
        amountRate: null,
      },
      sumUp: {
        type: null,
        backToDays: 0,
      },
      spec: {
        type: null,
        ids: null,
        names: null,
      },
      multiSpecs: [
        {
          type: 'memberStoreProduct',
          ids: null,
          names: null,
        },
        {
          type: 'salesProduct',
          ids: null,
          names: null,
        },
        {
          type: 'appointmentService',
          ids: null,
          names: null,
        },
        {
          type: 'ecommerceProduct',
          ids: null,
          names: null,
        },
        {
          type: 'memberExternalTransactionItem',
          ids: null,
          names: [
            {
              name: null,
              showOrderCount: false,
              count: 0,
            },
          ],
        },
      ],
      multiSources: [],
      subscriptionConfig: {
        planId: [],
        skipBenefitIfPaymentFailed: false,
      },
      singleConsumption: null,
    })
    const formRules = computed(() => {
      const rules = {
        source: [noEmptyRules('請輸入事件類型')],
        multiSources: [minArrayRules(2, '複選，至少兩項')],
        type: [noEmptyRules('請輸入事件範本')],
        amountConfig: [noEmptyRules('請選擇消費滿額設置')],
        spec: {
          ids: [noEmptyRules('請選擇指定商品')],
          names: [noEmptyArray()],
        },
        multiSpecs: [
          {
            type: 'memberStoreProduct',
            ids: [noEmptyRules('請選擇指定商品')],
          },
          {
            type: 'salesProduct',
            ids: [noEmptyRules('請選擇指定商品')],
          },
          {
            type: 'appointmentService',
            ids: [noEmptyRules('請選擇指定商品')],
          },
          {
            type: 'ecommerceProduct',
            ids: [noEmptyRules('請選擇指定商品')],
          },
          {
            type: 'memberExternalTransactionItem',
            names: [noEmptyArray()],
          },
        ],
        singleConsumption: [noEmptyRules('請選擇單筆消費規則')],
        subscriptionConfig: {
          planId: [noEmptyRules('請選擇指定訂閱方案')],
        },
      }
      const resetRules = () => {
        rules.amountConfig.amountRate = []
        rules.amountConfig.amountMin = []
        rules.amountConfig.amountRangeMin = []
        rules.amountConfig.amountMax = []
      }

      switch (formData.amountConfig.amountType) {
      case 'repeatRate':
        resetRules()
        rules.amountConfig.amountRate = [noEmptyRules()]
        break
      case 'threshold':
        resetRules()
        rules.amountConfig.amountMin = [noEmptyRules()]
        break
      case 'range':
        resetRules()
        rules.amountConfig.amountRangeMin = [noEmptyRules()]
        rules.amountConfig.amountMax = [noEmptyRules()]
        break
      }
      return rules
    })
    const { checkAction, checkActionList } = usePermissions()

    const createComputedProperty = (type, key) => {
      return computed({
        get: () => find(formData.multiSpecs, { type })?.[key] ?? null,
        set: (newValue) => {
          const item = find(formData.multiSpecs, { type })
          if (item) item[key] = newValue
        },
      })
    }
    const memberStoreOrderCompletedIds = createComputedProperty('memberStoreProduct', 'ids')
    const salesRecordCompletedIds = createComputedProperty('salesProduct', 'ids')
    const appointementOrderCompletedIds = createComputedProperty('appointmentService', 'ids')
    const ecOrderCompletedIds = createComputedProperty('ecommerceProduct', 'ids')
    const memberExternalTransactionCompletedNames = createComputedProperty('memberExternalTransactionItem', 'names')
    const showSingleConsumption = computed(() => {
      return singleConsumptionConfig.includes(formData.type)
    })
    const showMultiTransaction = computed(() => formData.source === 'multiSource')
    // 顯示指定訂閱方案
    const showSubscriptionPlan = computed(() => {
      return ['singlePaymentRecord', 'periodicBenefit'].includes(formData.type)
    })
    // 顯示權益發放限制
    const showBenefitLimit = computed(() => {
      return ['periodicBenefit'].includes(formData.type)
    })
    const isEdit = computed(() => {
      return !!get(props.eventData, 'id')
    })

    // 顯示選擇指定商品
    const showProductRow = (source) => {
      // if (!source) {
      //   return productConfig.includes(formData.type) && formData.source !== 'memberExternalTransactionCompleted'
      // }
      return (formData.source === source && productConfig.includes(formData.type))
    }
    // 顯示選擇指定商品(多管道)
    const showProductRowWithMulti = (source) => {
      return formData.multiSources.includes(source) && showMultiTransaction.value && productConfig.includes(formData.type)
    }

    // 顯示消費滿額設置
    const showAmountConfig = computed(() => {
      return amountSettingConfig.includes(formData.type)
    })

    // 顯示累積計算方式
    const showAccumulationConfig = computed(() => {
      return accumulationConfig.includes(formData.type)
    })

    const eventType = computed(() => {
      const current = featureKeysByEventSourceConfig.value.find((item) => {
        return item.value === formData.source
      })

      return current?.type || []
    })

    const sendLimitWord = computed(() => {
      if (!formData.singleConsumption) return ''
      return singleConsumptionWord[formData.singleConsumption]
    })
    //  mapping featureKeys
    const featureKeys = {
      appointmentOrderCompleted: 'admin.appointmentService.page',
      ecOrderCompleted: 'admin.ecommercePage.page',
      saleRecordCompleted: 'admin.salesRecord.page',
      memberStoreOrderCompleted: 'admin.memberStoreOrder.page',
      memberExternalTransactionCompleted: ['admin.memberExternalTransaction.page', 'admin.shopCustomModule.posPalMemberWallet'],
      subscriptionPlanBenefits: 'admin.subscriptionPlan.page',
    }

    const featureKeysByEventSourceConfig = computed(() => {
      return eventSourceConfig.filter(({ value }) => {
        const featureKey = get(featureKeys, value)
        if (!featureKey) return true
        if (isArray(featureKey)) return checkActionList(featureKey, 'intersection')
        return checkAction(featureKey)
      })
    })
    const featureKeysByEventSourceContainsConfig = computed(() => {
      return Object.values(eventSourceContainsConfig).filter(({ value }) => {
        const featureKey = get(featureKeys, value)
        if (!featureKey) return true
        if (isArray(featureKey)) return checkActionList(featureKey, 'intersection')
        console.log(featureKey, checkAction(featureKey))
        return checkAction(featureKey)
      })
    })

    const onSourceChange = (source) => {
      props.setSourceType(source)
      formData.type = null
    }

    const changeType = (type) => {
      props.setEventType(type)
    }

    const changeSingleConsumption = (type) => {
      props.setSingleConsumption(type)
      amountTypeChangeHandler()
      if (type === 'repeat') {
        formData.amountConfig.amountType = 'repeatRate'
      } else {
        formData.amountConfig.amountType = 'threshold'
      }
    }

    const compactData = computed(() => {
      const data = {
        source: get(formData, 'source'),
        type: get(formData, 'type'),
        multiSources: get(formData, 'multiSources'),
      }

      // 推薦禮
      if (['memberReferralRecordSuccessReferrer', 'memberReferralRecordSuccessReferee'].includes(formData.source)) {
        const { amountType, amountMin, amountRangeMin, amountMax } =
          formData.amountConfig

        data.sumUp = get(formData, 'sumUp')
        data.amountConfig = {
          amountType,
          amountMax: amountMax || 0,
          amountMin: amountMin || amountRangeMin || 0,
        }
      }

      if (amountSettingConfig.includes(formData.type)) {
        const { amountType, amountMin, amountRangeMin, amountMax } =
          formData.amountConfig
        data.amountConfig = {
          amountType,
          amountMax: amountMax || 0,
          amountMin: amountMin || amountRangeMin || 0,
        }
      }
      if (accumulationConfig.includes(formData.type)) {
        const { amountMin } = formData.amountConfig
        data.sumUp = get(formData, 'sumUp')
        data.amountConfig = {
          amountType: 'threshold',
          amountMin,
          amountMax: 0,
        }
      }
      if (showSingleConsumption.value && formData.singleConsumption === 'repeat') {
        data.amountConfig = {
          amountType: 'repeatRate',
          amountRate: get(formData.amountConfig, 'amountRate'),
          amountMin: 0,
        }
      }
      if (productConfig.includes(formData.type) && !showMultiTransaction.value) {
        data.spec = {
          type: eventSourceConfig.find(({ value }) => value === formData.source)
            ?.eventSpecsType,
          ids: formData.spec.ids ? formData.spec.ids : [],
          names: formData.spec.names ? map(formData.spec.names, item => item.name) : [],
        }
      }
      if (showMultiTransaction.value) {
        const multiSources = get(formData, 'multiSources', [])
        const mappedMultiSources = map(multiSources, source => eventSoruceMappingSpecType[source] || source)
        const filterdMultiSpecs = filter(formData.multiSpecs, item => includes(mappedMultiSources, item.type))

        if (['orderSumSpecAmount', 'orderSumSpecNumber'].includes(formData.type)) {
          data.multiSpecs = map(filterdMultiSpecs, item => {
            return {
              type: item.type ? item.type : null,
              ids: item.ids ? item.ids : [],
              names: item.names ? map(item.names, item => item.name) : [],
            }
          })
        } else {
          data.multiSpecs = undefined
        }
      }
      if (showSubscriptionPlan.value) {
        data.subscriptionConfig = {
          planId: get(formData, 'subscriptionConfig.planId[0]'),
          skipBenefitIfPaymentFailed: get(formData, 'subscriptionConfig.skipBenefitIfPaymentFailed'),
        }
      }
      return { ...data }
    })

    const syncData = () => {
      const data = props.eventData
      const { amountConfig, sumUp, spec, multiSources, multiSpecs, subscriptionConfig } = data.config || {}
      const { amountType, amountMin, amountMax, amountRate } = amountConfig || {}
      set(formData, 'source', data.source)
      set(formData, 'type', data.type)
      set(formData.amountConfig, 'amountType', amountType)
      set(formData.amountConfig, 'amountMax', amountMax)
      if (amountType === 'range') {
        set(formData.amountConfig, 'amountRangeMin', amountMin)
      } else {
        set(formData.amountConfig, 'amountMin', amountMin)
      }
      if (amountType === 'repeatRate') {
        set(formData.amountConfig, 'amountRate', amountRate)
        set(formData, 'singleConsumption', 'repeat')
      } else {
        set(formData, 'singleConsumption', 'once')
      }
      set(formData, 'sumUp', sumUp)
      props.setSourceType(data.source)
      if (sumUp) {
        props.setAccumulation(sumUp.type)
      }
      if (spec) {
        set(formData.spec, 'ids', spec.ids)
        set(formData.spec, 'names', map(spec.names, item => ({ name: item })))
        set(formData.spec, 'type', spec.type)
      }
      if (multiSources) {
        set(formData, 'multiSources', multiSources)
      }
      if (multiSpecs) {
        set(formData, 'multiSpecs', map(multiSpecs, item => {
          return {
            type: item.type,
            ids: item.ids,
            names: map(item.names, item => ({ name: item })),
          }
        }))
      }
      if (subscriptionConfig) {
        set(formData, 'subscriptionConfig', {
          planId: [subscriptionConfig.planId],
          skipBenefitIfPaymentFailed: subscriptionConfig.skipBenefitIfPaymentFailed,
        })
      }
    }

    onMounted(async () => {
      await nextTick()

      if (get(props.eventData, 'id')) syncData()
      props.FormsContext.setFormRef('setting', formRef.value)
    })

    watch(formData, () => {
      props.FormsContext.setFormData('setting', { ...compactData.value })
    })

    const amountConfig = computed(() => {
      if (textSettingConfig.includes(formData.type)) {
        const { configSpec, configRange } = eventTypeConsumption.find(
          ({ value }) => value === formData.type,
        )
        return { configSpec, configRange }
      }
      return null
    })

    const amountTypeChangeHandler = () => {
      formData.amountConfig.amountMin = null
      formData.amountConfig.amountRangeMin = null
      formData.amountConfig.amountMax = null
      formData.amountConfig.amountRate = null
      // formData.spec.ids = null
      if (formData.source === 'memberExternalTransactionCompleted') {
        formData.spec.names = [{
          name: null,
          showOrderCount: false,
          count: 0,
        }]
      }
    }

    const typeChangeHandler = () => {
      formData.amountConfig.amountType = 'threshold'
      formData.spec.ids = null
      amountTypeChangeHandler()
      if (!showAccumulationConfig.value) {
        props.setAccumulation(null)
        formData.sumUp.type = null
      }
    }

    return {
      formRef,
      formData,
      formRules,
      featureKeysByEventSourceConfig,
      featureKeysByEventSourceContainsConfig,
      eventSumUpConfigConfig,
      eventHistoryConfig,
      eventSourceContainsConfig,
      eventTypeConsumption,
      eventType,
      onSourceChange,
      showAmountConfig,
      amountConfig,
      amountTypeChangeHandler,
      typeChangeHandler,
      showAccumulationConfig,
      isEdit,
      changeType,
      showSubscriptionPlan,
      showBenefitLimit,
      showProductRow,
      showMultiTransaction,
      showProductRowWithMulti,
      showSingleConsumption,
      memberStoreOrderCompletedIds,
      salesRecordCompletedIds,
      appointementOrderCompletedIds,
      ecOrderCompletedIds,
      memberExternalTransactionCompletedNames,
      get,
      find,
      singleConsumptionTypeConfig,
      changeSingleConsumption,
      sendLimitWord,
    }
  },
})
</script>

<style lang="postcss" scoped>
.tips {
  @apply text-[#636363] text-sub leading-[20.27px] max-w-[560px] mt-[4px];
}
::v-deep .el-radio {
  @apply text-[#333];
}
::v-deep .el-select__tags {
  @apply top-[3px] translate-y-0;
}
::v-deep .el-select {
  @apply h-auto
}
::v-deep .el-select .el-input {
  @apply h-auto
}
</style>
