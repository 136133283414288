<template>
  <div class="event-message-block">
    <MessageBlock
      v-for="(block, index) in messages"
      :key="`block-${index}`"
      class="mb-3"
      :model.sync="messages[index]"
      :closeBtn="messages.length > 1"
      maxContent="500"
      @error="onMessageError = true"
      @checked="onMessageError = false"
      @close="removeNotifyMessage(index)"
    />
    <AddButton @click="addNotifyMessage" />
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import MessageBlock from '@/components/Message/MessageBlock.vue'

export default defineComponent({
  name: 'EventMessageBlock',
  components: { MessageBlock },
  props: ['eventMessage'],
  emits: ['update:eventMessage'],
  setup (props, { emit }) {
    const onMessageError = ref(false)
    const messages = computed({
      get: () => props.eventMessage,
      set: (newValue) => emit('update:eventMessage', newValue),
    })

    const addNotifyMessage = () => {
      messages.value.push({
        type: 'text',
        content: '',
        action: undefined,
        actionContent: undefined,
        Image: undefined,
      })
    }

    const removeNotifyMessage = (index) => {
      messages.value.splice(index, 1)
    }
    return {
      messages,
      addNotifyMessage,
      removeNotifyMessage,
      onMessageError,
    }
  },
})
</script>
