<template>
  <div class="event-appointment-product">
    <div v-for="(item, index) in currentProduct" :key="index" class="mb-[20px]">
      <div class="flex w-[560px]">
        <el-input
          v-model="currentProduct[index].name"
          placeholder="請輸入指定商品名稱"
          clearable
          :disabled="isDisabled"
          class="flex-1"
          @clear="clearProduct(index)"
          @keyup.enter.native="searchCount(currentProduct[index], index)"
          @blur="searchCount(currentProduct[index], index)"
        >
          <i
            v-if="!isDisabled"
            slot="suffix"
            class="el-icon-search el-input__icon"
            @click="searchCount(currentProduct[index], index)"
          />
        </el-input>
        <p v-if="currentProduct.length > 1 && !isDisabled" class="flex pl-[16px] underline text-danger cursor-pointer text-normal" @click="deleteProduct(index)">移除</p>
      </div>
      <p v-if="item.showOrderCount && !isDisabled" class="text-gray-80 text-sub leading-normal">目前符合第三方商品名稱的訂單數為 {{ item.count }} 筆 </p>
    </div>
    <p v-if="!isDisabled" class="inline-flex underline text-primary-100 cursor-pointer text-normal leading-normal" @click="addProduct">增加更多商品</p>
  </div>
</template>

<script>
import { defineComponent, ref, computed, set } from 'vue'
import store from '@/store'
import { GetMemberExternalTransactionCount } from '@/api/memberExternalTransaction'
import { map } from 'lodash'

export default defineComponent({
  name: 'EventExternalProduct',
  props: ['productName', 'isDisabled'],
  emits: ['update:productName'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const currentProduct = computed({
      get: () => props.productName,
      set: (newValue) => emit('update:productName', map(newValue, item => item.name)),
    })
    const count = ref(0)
    const showOrderCount = ref(false)
    const deleteProduct = (index) => {
      currentProduct.value.splice(index, 1)
    }
    const addProduct = () => {
      currentProduct.value.push({
        name: '',
        showOrderCount: false,
      })
    }
    const clearProduct = (index) => {
      set(currentProduct.value[index], 'showOrderCount', false)
      set(currentProduct.value[index], 'count', 0)
    }
    const searchCount = async (data, index) => {
      if (!data.name) return
      const [res, err] = await GetMemberExternalTransactionCount({
        shopId: shopId.value,
        itemName: data.name,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      set(currentProduct.value[index], 'showOrderCount', true)
      set(currentProduct.value[index], 'count', res.count)
    }

    return {
      currentProduct,
      searchCount,
      showOrderCount,
      count,
      clearProduct,
      addProduct,
      deleteProduct,
    }
  },
})
</script>
