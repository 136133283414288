import { admin2, orgAdmin } from '@/api/instance'

export const GetMemberExternalTransactionSourceOptions = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberExternalTransaction/sourceOptions`,
  })
}
export const GetMemberExternalTransaction = async ({
  shopId,
  source,
  transactionId,
  transactionTimeStart,
  transactionTimeEnd,
  start,
  limit,
  customerPhone,
  branchIds,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberExternalTransaction`,
    params: {
      source,
      transactionId,
      transactionTimeStart,
      transactionTimeEnd,
      start,
      limit,
      customerPhone,
      branchIds,
    },
  })
}

export const GetMemberExternalTransactionCount = async ({
  shopId,
  source,
  transactionId,
  transactionTimeStart,
  transactionTimeEnd,
  itemName,
  fuzzySearchItemName,
  customerPhone,
  branchIds,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberExternalTransaction/count`,
    params: {
      source,
      transactionId,
      transactionTimeStart,
      transactionTimeEnd,
      itemName,
      fuzzySearchItemName,
      customerPhone,
      branchIds,
    },
  })
}

export const FindMemberExternalTransaction = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberExternalTransaction/${id}`,
  })
}

// scope: orgAdmin
export const OrgAdminGetMemberExternalTransactionSourceOptions = async ({ orgId }) => {
  return await orgAdmin({
    method: 'GET',
    url: `/${orgId}/memberExternalTransaction/sourceOptions`,
  })
}
export const OrgAdminGetMemberExternalTransaction = async ({
  orgId,
  source,
  transactionId,
  transactionTimeStart,
  transactionTimeEnd,
  start,
  limit,
  customerPhone,
  shopIds,
}) => {
  return await orgAdmin({
    method: 'GET',
    url: `/${orgId}/memberExternalTransaction`,
    params: {
      source,
      transactionId,
      transactionTimeStart,
      transactionTimeEnd,
      start,
      limit,
      customerPhone,
      shopIds,
    },
  })
}

export const OrgAdminGetMemberExternalTransactionCount = async ({
  orgId,
  source,
  transactionId,
  transactionTimeStart,
  transactionTimeEnd,
  itemName,
  fuzzySearchItemName,
  customerPhone,
  shopIds,
}) => {
  return await orgAdmin({
    method: 'GET',
    url: `/${orgId}/memberExternalTransaction/count`,
    params: {
      source,
      transactionId,
      transactionTimeStart,
      transactionTimeEnd,
      itemName,
      fuzzySearchItemName,
      customerPhone,
      shopIds,
    },
  })
}

export const OrgAdminFindMemberExternalTransaction = async ({
  orgId,
  id,
}) => {
  return await orgAdmin({
    method: 'GET',
    url: `/${orgId}/memberExternalTransaction/${id}`,
  })
}
