<template>
  <div class="event-member-store-product">
    <el-select
      v-model="currentProduct"
      popper-class="select-popper"
      placeholder="選擇商品"
      clearable
      multiple
      :disabled="isDisabled"
      @visible-change="showProductSelectModal = true"
    >
      <el-option
        v-for="product in currentProduct"
        :key="product"
        :label="findProductName(product)"
        :value="product"
      />
    </el-select>

    <MemberShopProductAdvanceSelect
      v-if="showProductSelectModal"
      :data="storeProducts"
      multiple
      :select="currentProduct"
      :typeOptions="filteredProductType"
      @confirm="onAddProduct"
      @close="showProductSelectModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import MemberShopProductAdvanceSelect from '@/components/Select/MemberShopProductAdvanceSelect.vue'
import { productTypeConfig } from '@/config/memberShop'
import {
  GetMemberShopProduct,
  GetMemberShopProductCount,
} from '@/api/memberShop'
import store from '@/store'
import { getAllDataFromApi } from '@/utils/helper'
import { find, omit } from 'lodash'

export default defineComponent({
  name: 'EventMemberStoreProduct',
  components: { MemberShopProductAdvanceSelect },
  props: ['productId', 'isDisabled'],
  emits: ['update:productId'],
  setup (props, { emit }) {
    const showProductSelectModal = ref(false)
    const storeProducts = ref([])
    const shopId = computed(() => store.getters.shop)

    const currentProduct = computed({
      get: () => props.productId,
      set: (newValue) => emit('update:productId', newValue),
    })

    const filteredProductType = computed(() => {
      return omit(productTypeConfig, ['cashback', 'coupon'])
    })
    const findProductName = (id) => {
      const product = find(storeProducts.value, { id })
      if (product) return product.name
      return ''
    }

    const onAddProduct = (product) => {
      if (product.length > 0) {
        currentProduct.value = product
      }
    }

    const getProductCount = async () => {
      const [res, err] = await GetMemberShopProductCount({
        shopId: shopId.value,
      })
      if (err) throw err
      return res.count
    }

    const getAllStoreProducts = async () => {
      let max
      try {
        max = await getProductCount()
      } catch (error) {
        window.$message.error(error)
        return
      }
      const config = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
      }
      const [res, err] = await getAllDataFromApi(
        max,
        GetMemberShopProduct,
        config,
        true,
      )
      if (err) return window.$message.error(err)
      storeProducts.value = res
    }

    onMounted(() => {
      getAllStoreProducts()
    })

    return {
      storeProducts,
      currentProduct,
      findProductName,
      onAddProduct,
      showProductSelectModal,
      productTypeConfig,
      filteredProductType,
    }
  },
})
</script>
