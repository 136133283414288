<template>
  <div class="event-coupon-select">
    <el-select
      v-model="currentCoupon"
      popper-class="select-popper"
      placeholder="選擇票券"
      clearable
      @visible-change="showCouponSelectModal = true"
    >
      <el-option
        :label="findCouponName(currentCoupon)"
        :value="currentCoupon"
      />
    </el-select>
    <p v-if="isEmpty" class="text-danger text-sm leading-normal">找不到先前綁定的項目，請重新綁定</p>
    <CouponAdvanceSelect
      v-if="showCouponSelectModal"
      :data="couponList"
      :typeOptions="filterdCouponType"
      :disabledExp="true"
      @confirm="onAddCoupon"
      @close="showCouponSelectModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import CouponAdvanceSelect from '@/components/Select/CouponAdvanceSelect.vue'
import { couponTypeConfig } from '@/config/couponExchange'
import { getAllDataFromApi } from '@/utils/helper'
import { GetCoupon, GetCouponCount } from '@/api/lottery/coupon'
import { find } from 'lodash'
import store from '@/store'
import FormsContext from '../formsContext'

export default defineComponent({
  name: 'EventCouponSelect',
  components: { CouponAdvanceSelect },
  props: ['couponId', 'couponType'],
  emits: ['update:couponId'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const couponList = ref([])
    const showCouponSelectModal = ref(false)
    const isEmpty = ref(false)
    const filterdCouponType = computed(() => {
      const filteredConfig = {}

      props.couponType.forEach(type => {
        if (couponTypeConfig[type]) {
          filteredConfig[type] = couponTypeConfig[type]
        }
      })

      return filteredConfig
    })
    const currentCoupon = computed({
      get: () => props.couponId,
      set: (newValue) => emit('update:couponId', newValue),
    })

    const findCouponName = (id) => {
      const coupon = find(couponList.value, { id })
      if (coupon) return coupon.name
      return ''
    }

    const getCouponCount = async () => {
      const [res, err] = await GetCouponCount({
        shopId: shopId.value,
        type: 'all',
      })
      if (err) throw err
      return res.count || 0
    }

    const getAllCoupon = async () => {
      let max
      try {
        max = await getCouponCount()
      } catch (error) {
        window.$message.error(error)
        return
      }
      const config = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
        type: props.couponType.length === 1 ? props.couponType[0] : 'all', // 'coupon','exchange','all'
      }
      const [res, err] = await getAllDataFromApi(max, GetCoupon, config, true)
      if (err) return window.$message.error(err)
      couponList.value = res
    }

    const onAddCoupon = (coupon) => {
      if (coupon.length > 0) {
        currentCoupon.value = coupon[0]
        const couponData = find(couponList.value, { id: coupon[0] })
        findCoupon(coupon[0])
        FormsContext.setTempData('relateData', couponData)
        emit('change', couponData)
      }
    }
    const findCoupon = async (id) => {
      if (id === null) {
        isEmpty.value = false
        return
      }
      const coupon = find(couponList.value, { id })
      if (coupon) isEmpty.value = false
      else isEmpty.value = true
    }
    onMounted(async () => {
      await getAllCoupon()
      await findCoupon(currentCoupon.value)
    })

    return {
      currentCoupon,
      couponTypeConfig,
      couponList,
      showCouponSelectModal,
      findCouponName,
      onAddCoupon,
      filterdCouponType,
      isEmpty,
    }
  },
})
</script>

<style lang="postcss">
.select-popper {
  @apply hidden;
}
</style>
